import axios from 'api-axios';
import Loading from 'components/loading';
import insightsConfig from 'config/config';
import { FilterContext } from 'hooks/useFilters';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const RedirectToActualUrl = () => {
  const { sharedUrlId } = useParams();
  const navigate = useNavigate();
  const { storageKey = '' } = useContext(FilterContext);

  useEffect(() => {
    const fetchAndRedirect = async () => {
      try {
        const url = `${insightsConfig.baseURL.saveFiltersAPI}/FetchUrl`;
        const reqBody = { reference_id: sharedUrlId };
        const response = (await axios.post(url, reqBody)) as any;
        if (storageKey) {
          localStorage.setItem(storageKey, response?.response?.filters);
          navigate(response?.response?.insights_url, { replace: true, state: undefined });
        }
      } catch (err) {
        navigate('/404');
      }
    };

    fetchAndRedirect();
  }, [navigate, sharedUrlId, storageKey]);

  return <Loading />;
};

export default RedirectToActualUrl;
