import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MailSvg from 'assets/remix-icons/line/business/global-line.svg';
import ContactSvg from 'assets/remix-icons/line/device/phone-line.svg';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../hooks/useAuth';

export default function HelpSupport() {
  const navigate = useNavigate();
  const { updateUiConfigFnc } = useContext(AuthContext);

  const toggleLeftNav = (show: boolean) => {
    updateUiConfigFnc({
      payload: {
        event: 'updateUiConfig',
        data: { showLeftNav: show }
      }
    });
  };

  const handleBackButtonClick = () => {
    toggleLeftNav(true);
    navigate(-1);
  };

  useEffect(() => {
    toggleLeftNav(false);
  }, []);

  return (
    <main
      style={{
        height: 'calc(100vh - 148px)',
        padding: '1rem',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <IconButton aria-label="back button" onClick={handleBackButtonClick}>
          <ChevronLeftIcon
            sx={{
              color: 'white',
              backgroundColor: '#FF2A00',
              borderRadius: '50%',
              fontSize: 'medium',
              padding: '4px'
            }}
          />
        </IconButton>
        <h1 style={{ marginLeft: '6px' }}>Help and Support</h1>
      </div>
      <section
        style={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p style={{ fontSize: '18px', color: '#858997' }}>For support, reach out to us on</p>
        <address
          style={{
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontStyle: 'normal'
          }}
        >
          <img src={MailSvg} alt="mail to" className="icon" style={{ padding: '0.5rem' }}></img>
          <Link
            href="mailto:service@greyscaleai.com"
            style={{
              marginRight: '15px',
              color: '#FF2A00',
              textDecorationColor: '#FF2A00',
              fontWeight: 'bold',
              fontSize: '14px'
            }}
          >
            service@greyscaleai.com
          </Link>
          <img
            src={ContactSvg}
            alt="contact at"
            className="icon"
            style={{ padding: '0.5rem' }}
          ></img>
          <Link
            href="tel:+16502327974"
            underline="none"
            style={{
              color: '#000000',
              fontSize: '13px'
            }}
          >
            (650) 232-7974
          </Link>
        </address>
      </section>
    </main>
  );
}
