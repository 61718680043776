import tokenizedAxios from 'api-axios';
import insightsConfig from 'config/config';

class RoleService {
  private static instance: RoleService;
  private baseUrl = insightsConfig.baseURL.userSessionAPI;

  public static getInstance(): RoleService {
    if (!RoleService.instance) {
      RoleService.instance = new RoleService();
    }
    return RoleService.instance;
  }

  private handleError(error: any): any {
    console.error('Error:', error);
    throw error;
  }

  public async fetchRole(
    email: string,
    name: string,
    cognitoGroups: string[] | undefined,
    cognitoToken: string,
    last_login: number,
    resfreshSession = false
  ): Promise<any> {
    try {
      const response = await tokenizedAxios.post(`${this.baseUrl}/FetchSession`, {
        email_id: email,
        name: name,
        auth_group: cognitoGroups,
        location_type: insightsConfig.featureToggles.isEnableMultipleTabs ? 'Multiple' : 'Single',
        last_login,
        ...(resfreshSession ? { refresh_session: resfreshSession } : {})
      });
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }

  public async fetchAllUsers(session_id: string): Promise<any> {
    try {
      const response = await tokenizedAxios.get(`${this.baseUrl}/FetchUsers`, {
        params: { session_id }
      });
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }

  public async fetchFactoryList(session_id: string): Promise<any> {
    try {
      const response = await tokenizedAxios.get(`${this.baseUrl}/FetchFactories`, {
        params: { session_id }
      });
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }

  public async updateUser(
    session_id: string,
    userData: any,
    operation: 'Add' | 'Update'
  ): Promise<any> {
    try {
      const response = await tokenizedAxios.post(`${this.baseUrl}/${operation}User`, userData, {
        params: { session_id }
      });
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }
}

export default RoleService;
