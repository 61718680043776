import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography
} from '@mui/material';
import { AdminContacts } from 'components/error-handlers/greyscale-contacts';
import React, { useContext, useEffect } from 'react';

import { AuthContext } from '../../hooks/useAuth';
import './profile.scss';

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none'
        },
        '.expandIconWrapper': {
          display: 'none'
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block'
        }
      }}
    >
      <div className="collapsIconWrapper">
        <HelpOutlineIcon style={{ marginLeft: '10px' }} />
      </div>
      <div className="expandIconWrapper">
        <ExpandMoreIcon />
      </div>
    </Box>
  );
};

function Profile() {
  const { updateUiConfigFnc, authDetails } = useContext(AuthContext);
  const { roleDetails } = authDetails || {};
  const { name, email_id, role, factory_access, status, help_info } = roleDetails || {};
  useEffect(() => {
    updateUiConfigFnc({
      payload: {
        event: 'updateUiConfig',
        data: { showLeftNav: true }
      }
    });
  }, []);
  return (
    <>
      <div
        style={{
          margin: '15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="h5">My Info</Typography>
      </div>
      <Container style={{ maxWidth: 800 }}>
        <Paper
          style={{
            padding: '20px',
            marginTop: '20px',
            borderRadius: 0,
            boxShadow: '2px 2px 7px 0px rgba(0, 0, 0, 0.2)'
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <div className="flex-profile">
                <Typography variant="h6" sx={{ color: 'grey', fontSize: '1rem' }}>
                  Name
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.15rem', fontWeight: 400 }}>
                  {name || '-'}
                </Typography>
              </div>
              <Divider />
              <div className="flex-profile">
                <Typography variant="h6" sx={{ color: 'grey', fontSize: '1rem' }}>
                  Email
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.15rem', fontWeight: 400 }}>
                  {email_id || '-'}
                </Typography>
              </div>
              <Divider />
              <div className="flex-profile">
                {help_info ? (
                  <Accordion elevation={0} sx={{ width: '100%' }}>
                    <Tooltip
                      title="Click to know more about your company admins and their contact details"
                      placement="right"
                    >
                      <AccordionSummary
                        expandIcon={<CustomExpandIcon />}
                        sx={{ padding: 0, margin: 0 }}
                      >
                        <div className="flex-role-accordian">
                          <Typography variant="h6" sx={{ color: 'grey', fontSize: '1rem' }}>
                            Role
                          </Typography>
                          <Typography variant="body1" sx={{ fontSize: '1.15rem', fontWeight: 400 }}>
                            {role || '-'}
                          </Typography>
                        </div>
                      </AccordionSummary>
                    </Tooltip>
                    <AccordionDetails>
                      {help_info.map((contact, index) => (
                        <div key={index}>
                          <AdminContacts {...contact} />
                          <Divider style={{ padding: '5px' }} />
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <>
                    <Typography variant="h6" sx={{ color: 'grey', fontSize: '1rem' }}>
                      Role
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '1.15rem', fontWeight: 400 }}>
                      {role || '-'}
                    </Typography>
                  </>
                )}
              </div>
              <Divider />
              <div className="flex-profile">
                <Typography variant="h6" sx={{ color: 'grey', fontSize: '1rem' }}>
                  Status
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.15rem', fontWeight: 400 }}>
                  {status || '-'}
                </Typography>
              </div>
              <Divider />
              <div className="flex-profile">
                <Typography variant="h6" sx={{ color: 'grey', fontSize: '1rem' }}>
                  Locations Access
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.15rem', fontWeight: 400 }}>
                  {factory_access ? JSON.parse(factory_access).join('; ') : '-'}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}

export default Profile;
