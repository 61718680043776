import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import Loading from '../loading';

const CognitoLogOut = (): JSX.Element => {
  const [cognitoLogOutCalled, setCognitoLogOutCalled] = useState(false);

  useEffect(() => {
    logOut();
  }, []);

  const logOut = () => {
    Auth.signOut().then(() => {
      console.log('signed out the user');
      setCognitoLogOutCalled(true);
    });
  };

  if (cognitoLogOutCalled) {
    return <Navigate replace to={'/signOut'} />;
  }

  return <Loading text={'Signing Out...'} />;
};

export default CognitoLogOut;
