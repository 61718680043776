import { Button } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../hooks/useAuth';

function ExpiredSignInAgain() {
  const { updateUiConfigFnc } = useContext(AuthContext);
  const toggleLeftNav = (show: boolean) => {
    updateUiConfigFnc({
      payload: {
        event: 'updateUiConfig',
        data: { showLeftNav: show }
      }
    });
  };

  useEffect(() => {
    toggleLeftNav(false);
  }, []);

  return (
    <main
      style={{
        height: 'calc(100vh - 148px)',
        padding: '1rem',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <p style={{ fontSize: '16px', fontWeight: '700' }}>
        Session has expired. Please sign in again
      </p>
      <Button
        variant="contained"
        component={Link}
        to={'/'}
        style={{ backgroundColor: '#FF5300', borderRadius: '0px' }}
      >
        Sign in
      </Button>
    </main>
  );
}

export default ExpiredSignInAgain;
